<template>
  <div class="patrol">
    <!-- <el-button class="btn" type="primary" icon="el-icon-arrow-left" @click="goback()">返回</el-button> -->
    <div class="patrol-center">
      <div class="left">
        <div class="box1">
          <div class="topbox">
            <TitleBox :titlename="'水环境数据采集'" />
            <div class="imgbox">
              <p class="textbox">
                {{this.gatherlist[0].num + this.gatherlist[1].num}}
              </p>
              <p class="namebox">采集数据总数</p>
            </div>
            <div class="listbox">
              <div class="list" v-for="(item,index) in gatherlist" :key="index">
                <div class="titlebox">
                  <p>{{item.name}}</p>
                </div>
                <div class="numbox">
                  {{item.num}}
                </div>
              </div>
            </div>
          </div>
          <div class="bottombox">
            <TitleBox :titlename="'豚类/鱼类采集总数'" />
            <div class="pillarbox">
              <Echartspillar id="Echartspillar" v-if="flagFX" class="echarbiongt"
                :curvehydronum="curvehydronum" />
            </div>
          </div>
        </div>
        <div class="box1">
          <div class="topbox">
            <TitleBox :titlename="'当日水温趋势'" />
            <Switchbox :titlelist="['玻璃厂','江滩公园','石矶头','乌林水厂','鸭栏码头']" @swAreabtn="swAreabtn" />
            <div class="pi3dnpx">
              <Echartstemp id="Echartstemp" v-if="flagFX" class="echarbiongt"
                :trendlist="trendlist" />
            </div>
          </div>
          <div class="bottombox">
            <div class="btmscurvebox">
              <Echartscurve id="Echartscurvesid" v-if="flagFX" class="echarbiongt"
                :curvelist="curvehydrolist" />
            </div>
          </div>
        </div>
      </div>
      <div class="rigth">
        <div class="box3">
          <div class="topbox">
            <TitleBox :titlename="'AQI趋势'" />
            <div class="curvebox">
              <Echartscurve id="Echartscurveaqi" v-if="flagFX" class="echarbiongt"
                :curvelist="curvelist" />
            </div>
          </div>
          <div class="bottombox">
            <TitleBox :titlename="'空气质量状况'" />
            <div class="pillarbox">
              <div class="tabbox">
                <ul>
                  <li>
                    <p>日期</p>
                    <p>空气质量</p>
                    <p>aqi</p>
                  </li>
                  <li v-for="(item,index) in tableData" :key="index">
                    <p>{{item.fxDate}}</p>
                    <p>{{item.category}}</p>
                    <p>{{item.aqi}}</p>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
        <div class="box4">
          <div class="topbox">
            <TitleBox :titlename="'空气质量指数'" />
            <div class="top_centerbox">
              <div class="weatherbox">
                <Echartsweather id="Echartsweatherid" v-if="flagFX" class="echarbiongt"
                  :numobj="numobj" />
              </div>
              <div class="schedule">
                <div class="listbox" v-for="(item,index) in schedulelist" :key="index">
                  <p>{{item.name}}</p>
                  <p>{{item.text}}</p>
                  <p class="serialPri">
                    <span class="serialPriSpan">
                      <i :style="`width:${item.text >= 100 ? '100' : item.text}%`"></i>
                    </span>
                  </p>

                </div>
              </div>
            </div>
          </div>
          <div class="centerbox">
            <TitleBox :titlename="'空气优良统计和首要污染物'" />
            <div class="breath">
              <div class="mapbox">
                <p>优良天数</p>
                <Echartsbreath id="Echartsbreathid" v-if="flagFX" class="echarbiongt"
                  :colorTemplate1="colorTemplate" :data1="dataday" />
              </div>
              <div class="mapbox">
                <p>PM2.5</p>
                <Echartsbreath id="Echartsbreathpm" v-if="flagFX" class="echarbiongt"
                  :colorTemplate1="colorTemplatepm" :data1="datapm" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Switchbox from '../visualization/components/title/switch.vue'

import TitleBox from '../visualization/components/title/index.vue'
import Echartspillar from '../visualization/components/echarts/echartspillar.vue'
import Echartstemp from '../visualization/components/echarts/echartstemp.vue'
import Echartsweather from '../visualization/components/echarts/echartsweather.vue'
import Echartsbreath from '../visualization/components/echarts/echartsbreath.vue'
import Echartscurve from '../visualization/components/echarts/echartscurve.vue'
export default {
  props: {},
  components: {
    TitleBox,
    Echartstemp,
    Echartsweather,
    Echartsbreath,
    Echartscurve,
    Echartspillar,
    Switchbox
  },
  data () {
    return {
      flagFX: true,
      tableData: [],
      gatherlist: [
        {
          name: '正常采集数据',
          num: 212
        },
        {
          name: '采集异常数据',
          num: 212
        }
      ],
      title: '',
      schedulelist: [
        {
          name: 'PM2.5',
          text: 13
        },
        {
          name: 'PM10',
          text: 20

        },
        {
          name: 'SO2',
          text: 3

        },
        {
          name: 'NO2',
          text: 13
        },
        {
          name: 'CO',
          text: 29

        },
        {
          name: 'O3',
          text: 7

        }
      ],
      colorTemplate: [[0.2, 'rgba(255,0,0,0.8)'], [0.8, 'rgba(0,255,255,0.8)'], [1, 'rgba(0,255,0,0.8)']],
      dataday: [{
        name: '天',
        value: 0,
        max: 5,
        splitNumber: 5
      }],
      colorTemplatepm: [[1, 'rgba(0,255,0,0.8)']],
      datapm: [{
        name: 'pg/m³',
        value: 42,
        max: 100,
        splitNumber: 10
      }],
      curvelist: {
        xlist: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        ylist: [
          {
            name: 'AQI趋势',
            data: [10, 10, 30, 12, 15, 3, 7, 30, 12, 15, 3, 7]
          }
        ]
      },
      curvehydrolist: {
        xlist: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        ylist: [
          {
            name: '数据1',
            data: [10, 10, 10, 12, 15, 1, 7, 10, 12, 15, 1, 7]
          },
          {
            name: '数据2',
            data: [30, 30, 30, 32, 35, 3, 7, 30, 32, 35, 3, 7]
          }
        ]
      },
      curvehydronum: [
        { value: 75, name: '鱼类' },
        { value: 25, name: '豚类' }
      ],
      Finlessnum: 0,

      othernum: 0,
      numobj: {
        text: '',
        value: ''
      },
      collectionlist: null,
      trendlist: {
        data: [],
        className: []
      }

    }
  },
  computed: {},
  watch: {},
  methods: {
    swAreabtn (index) {
      if (index === 0) {
        this.pageTemperature('玻璃厂')
      } else if (index === 1) {
        this.pageTemperature('洪湖江滩公园')
      } else if (index === 2) {
        this.pageTemperature('石矶头')
      } else if (index === 3) {
        this.pageTemperature('乌林水厂')
      } else if (index === 4) {
        this.pageTemperature('鸭栏码头')
      }
      // var obj = this.WaterQualitydata[index].data
      // this.WaterQualitylist = [obj.temp, obj.ddo, obj.ct, obj.tur, obj.ph]
    },
    // 查询水下声学异常图表
    pageacoustic (index) {
      const devId = '220506394700040'
      axios.get(this.$constant.zsqyUrl + '/zsqy/waterincident/findByDate?' + 'type=' + index + '&devId=' + devId,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.Finlessnum = 0
          this.curvehydrolist.ylist = []
          this.curvehydrolist.xlist = res.data.data.xlist
          res.data.data.ylist.forEach(ele => {
            if (ele.name === '江豚声学识别') {
              this.curvehydrolist.ylist.push(ele)
              ele.data.forEach(ele2 => {
                if (ele2) {
                  this.Finlessnum = this.Finlessnum + ele2
                } else {
                  this.Finlessnum = this.Finlessnum + 0
                }
                this.curvehydronum[0].value = this.Finlessnum
                this.curvehydronum[0].name = ele.name
              })
            } else if (ele.name === '其他') {
              this.curvehydrolist.ylist.push(ele)
              ele.data.forEach(ele2 => {
                if (ele2) {
                  this.othernum = this.othernum + ele2
                } else {
                  this.othernum = this.othernum + 0
                }
                this.curvehydronum[1].value = this.othernum
                this.curvehydronum[1].name = ele.name
              })
            }
          })
        })
    },
    searchWeather: async function () {
      const key = '10198e0e3b94479cbe68010db4c0e8dd'
      const id = 101200806
      const httpUrl1 = `https://devapi.qweather.com/v7/air/now?location=${id}&key=${key}`
      const res1 = await fetch(httpUrl1)
      const result1 = await res1.json()
      const httpUrl2 = `https://devapi.qweather.com/v7/air/5d?location=${id}&key=${key}`
      const res2 = await fetch(httpUrl2)
      const result2 = await res2.json()
      this.curvelist.xlist = []
      this.curvelist.ylist[0].data = []
      this.tableData = result2.daily
      this.tableData.forEach(ele => {
        this.curvelist.xlist.push(ele.fxDate)
        this.curvelist.ylist[0].data.push(ele.aqi)
        if (ele.aqi * 1 <= 100) {
          this.dataday[0].value = this.dataday[0].value + 1
        }
      })
      this.numobj.text = result1.now.category
      this.numobj.value = result1.now.aqi
      this.datapm[0].value = result1.now.pm2p5
      this.schedulelist[0].text = result1.now.pm2p5
      this.schedulelist[1].text = result1.now.pm10
      this.schedulelist[2].text = result1.now.so2
      this.schedulelist[3].text = result1.now.no2
      this.schedulelist[4].text = result1.now.co
      this.schedulelist[5].text = result1.now.o3
    },
    // 查询水环境异常
    pagecollection () {
      axios.get(this.$constant.zsqyUrl + '/zsqy/drinfo/findWaterEnvironmentData',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.collectionlist = res.data.data
          this.gatherlist[0].num = this.collectionlist.normalNumberOfData
          this.gatherlist[1].num = this.collectionlist.numberOfDataExceptions
        })
    },
    // 当日水温趋势
    pageTemperature (name) {
      axios.get(this.$constant.zsqyUrl + '/Waterquality/findTimePeriodData?' + 'devName=' + name,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          const totalEnergyData = res.data.data
          const totaledX = []
          const totaledY = []
          for (var key in totalEnergyData) {
            totaledX.push(totalEnergyData[key])
            totaledY.push(key)
          }
          this.trendlist.data = totaledX
          this.trendlist.className = totaledY
        })
    }
  },
  created () {
    this.pageacoustic(1)
    this.searchWeather()
    this.pagecollection()
    this.pageTemperature('玻璃厂')
    document.title = '环境监测'
  }
}
</script>
<style lang="less" scoped>
ul li {
  margin: 0;
  padding: 0;
}
.echarbiongt {
  width: 100%;
  height: 100%;
}
/deep/.el-progress path:first-child {
  stroke: rgba(251, 254, 255, 0.12);
}
.patrol {
  .btn {
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 2;
  }
  width: 100%;
  height: 100%;
  background: url('../../assets/homeimg/bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  &-center {
    width: 90%;
    height: 75%;
    margin: 100px 0 1% 2%;
    background: url('../../assets/homeimg/k.png') no-repeat;
    background-size: 100% 100%;
    margin-left: 5%;
    overflow: hidden;
    display: flex;
    padding: 1%;
    .left {
      width: 42%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      .box1 {
        width: 48%;
        height: 100%;
        overflow: hidden;
        .topbox {
          width: 100%;
          height: 55%;
          // background: red;
          .imgbox {
            width: 70%;
            height: 60%;
            background: url('../../assets/homeimg/yt.png') no-repeat;
            background-size: 100% 100%;
            margin-left: 15%;
            font-size: 30px;
            // font-family: Arial Black;
            font-weight: 400;
            color: #e6fcff;
            overflow: hidden;
            .textbox {
              margin-top: 34%;
              text-align: center;
            }
            .namebox {
              font-size: 12px;
              text-align: center;
              margin-top: -12%;
            }
          }
          .listbox {
            width: 100%;
            height: 38%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: center;
            font-size: 14px;
            color: #fff;
            overflow: hidden;
            .list {
              width: 48%;
              height: 50%;
              margin-top: 10%;
              .titlebox {
                width: 90%;
                margin-left: 5%;
                border-left: 4px solid #fff;
              }
              .numbox {
                // font-size: 14px;
                line-height: 10px;
              }
            }
          }
          .pi3dnpx {
            width: 90%;
            height: 90%;
            // background: red;
            // margin-top: 5%;
            margin-left: 5%;
          }
        }
        .bottombox {
          margin-top: 5%;
          width: 100%;
          height: 40%;
          .pillarbox {
            width: 100%;
            height: 90%;
            // background: red;
          }
          .btmscurvebox {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .rigth {
      width: 55%;
      height: 100%;
      margin-left: 3%;
      display: flex;
      .box3 {
        width: 43%;
        height: 100%;
        // background: red;
        .topbox {
          width: 100%;
          height: 45%;
          // background: red;
          .curvebox {
            width: 100%;
            height: 100%;
          }
        }
        .bottombox {
          margin-top: 5%;
          width: 100%;
          height: 55%;

          .tabbox {
            width: 100%;
            height: 73%;
            margin-top: 15px;
            div {
              z-index: 2;
            }
            ul {
              width: 100%;
              height: 84%;
              overflow-y: auto;

              li {
                width: 100%;
                height: 20%;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                line-height: 40px;
                // background: RGBA(56, 74, 97, 0.2);
                p {
                  width: 33%;
                  color: #fff;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  -o-text-overflow: ellipsis;
                  text-align: center;
                  font-size: 15px;
                  font-family: SourceHanSansCN;
                  font-weight: 300;
                }
              }
              li:hover {
                background: rgba(79, 119, 163, 0.4);
              }
            }
          }
        }
      }
      .box4 {
        // background: red;
        width: 55%;
        height: 100%;
        margin-left: 2%;
        overflow: hidden;
        .topbox {
          width: 100%;
          height: 31%;
          margin-top: 2%;
          .top_centerbox {
            width: 100%;
            height: 85%;
            display: flex;
            .weatherbox {
              width: 40%;
              height: 100%;
            }
            .schedule {
              // background: red;
              color: #fff;
              text-align: center;
              width: 60%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .listbox {
                width: 30%;
                height: 40%;
                p:nth-child(2) {
                  color: #42f4ff;
                }
                p.serialPri {
                  width: 100%;
                  height: 5px;
                  display: flex;
                  align-items: center;
                  .serialPriSpan {
                    position: relative;
                    width: 100%;
                    height: 9px;
                    background: rgba(222, 246, 253, 0.2);
                    border-radius: 10px;
                    display: inline-block;
                    i {
                      border-radius: 10px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      height: 9px;
                      background: rgba(222, 246, 253, 1);
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
        .centerbox {
          width: 100%;
          height: 75%;
          // background: red;
          margin-top: 2%;
          .breath {
            width: 100%;
            height: 85%;
            // background: red;
            // display: flex;
            // justify-content: space-between;
            overflow: hidden;
            .mapbox {
              width: 100%;
              height: 50%;
              // margin-top: 4%;
              position: relative;
              p {
                position: absolute;
                // top: -5%;
                left: 0;
                width: 100%;
                height: 20px;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
