<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'numobj'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    numobj: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          // console.log(newvalue, '-=')
          this.numobj = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        title: [{
          show: true,
          text: this.numobj.text,
          x: '48%',
          y: '55%',
          z: 8,
          textAlign: 'center',
          textStyle: {
            color: 'RGBA(73, 234, 119, 1)',
            fontWeight: 'none',
            fontSize: 50
          }

        }],
        grid: {
          top: '0%'
        },
        series: [{
          name: '内部进度条',
          type: 'gauge',
          // center: ['20%', '50%'],
          radius: '80%',

          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [this.numobj.value / 500, 'RGBA(217, 241, 250, 0.8)'],
                [1, 'RGBA(58, 98, 131, 0.4)']
              ],
              width: 10
            }
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false

          },
          splitLine: {
            show: false
          },
          pointer: {
            show: false
          }

        },
        /* 内部 */
        {
          type: 'pie',
          radius: ['0', '50%'],
          center: ['50%', '50%'],
          z: 8,
          hoverAnimation: false,
          data: [{
            name: '',
            value: this.numobj.value,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: 'RGBA(79, 118, 150, 1)'
                }, {
                  offset: 1,
                  color: 'RGBA(58, 98, 131, 0.4)'
                }])
              }

            },
            label: {
              normal: {
                rich: {
                  a: {
                    color: ' #fff',
                    align: 'center',
                    fontWeight: 'none'
                  }
                },
                formatter: function (params) {
                  return '{a|' + params.value + '}'
                },
                position: 'center',
                show: true
              }
            },
            labelLine: {
              show: false
            }
          }]
        }

        ]
      })
    }
  }
}
</script>
