<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'trendlist'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    trendlist: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          // console.log(newvalue)
          this.trendlist = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      //   var colorList = ['#B3D2EE']

      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        legend: {
          data: [''],
          icon: 'circle',
          bottom: 10,
          textStyle: {
            color: '#ffffff'
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          top: '0%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].name + '<br/>' +
              "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
              // params[0].seriesName + ' : ' + Number((params[0].value.toFixed(4) / 10000).toFixed(2)).toLocaleString() + ' <br/>'
              params[0].seriesName + ' : ' + params[0].value
          }
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'none'
            }
          }

        },
        yAxis: [{
        //   name: '单位:℃',
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff'
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: true
          },
          axisLine: {
            show: false
          },
          data: this.trendlist.className
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            textStyle: {
              color: '#ffffff',
              fontSize: '12'
            },
            formatter: function (value) {
              return value
            }
          },
          data: this.trendlist.data
        }],
        series: [{
          name: '',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            normal: {
              // barBorderRadius: 30,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'RGBA(0, 91, 95, 1)' //  0%  处的颜色
                  },
                  {
                    offset: 1,
                    color: 'RGBA(1, 234, 249, 1)' //  100%  处的颜色
                  }
                ],
                global: false //  缺省为  false
              }
            }
          },
          barWidth: ' 30%',
          data: this.trendlist.data
        }
        ]
      })
    }
  }
}
</script>
