<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'curvehydronum'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    curvehydronum: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.curvehydronum = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      // var className = [
      //   { value: 75, name: '鱼类' },
      //   { value: 25, name: '豚类' }
      // ]
      //   var colorList = ['#B3D2EE']
      // console.log(this.curvehydronum, '90')
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        title: {
          text: '',
          left: 20,
          top: 20,
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
            fontWight: 400
          }
        },
        color: ['#00D488', '#3FEED4'],
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '信用指数',
            type: 'pie',
            radius: '50%',
            clockWise: false,
            data: this.curvehydronum,
            label: {
              color: '#fff'
            },
            labelLine: {
              length2: 35,
              length: 20,
              lineStyle: {
                cap: 'round'
              }
            },
            itemStyle: {
              borderColor: '#037D66',
              borderWidth: 5
            }
          }
        ]
      })
    }
  }
}
</script>
